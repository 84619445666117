(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['contactform.FieldAnimationSkin'] = {
  "react": [
    [
      "form",
      "form-wrapper",
      [],
      {
        "role": "form"
      },
      [
        "div",
        "wrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_flex-wrapper"
          ],
          {},
          [
            "div",
            "field1-hook",
            [],
            {}
          ],
          [
            "div",
            "field2-hook",
            [],
            {}
          ]
        ],
        [
          "div",
          "fieldN-hook",
          [],
          {}
        ],
        [
          "label",
          "fieldMessageContainer",
          [
            "_input-container"
          ],
          {},
          [
            "textarea",
            "fieldMessage",
            [
              "_input"
            ],
            {
              "required": true
            }
          ],
          [
            "div",
            null,
            [
              "_text-area-cover"
            ],
            {},
            [
              "span",
              "label_fieldMessage",
              [
                "_label"
              ],
              {}
            ]
          ]
        ],
        [
          "div",
          null,
          [
            "_button-wrapper"
          ],
          {},
          [
            "button",
            "submit",
            [
              "_submit-button"
            ],
            {
              "type": "submit"
            }
          ]
        ],
        [
          "div",
          "notifications",
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "fnt": "FONT",
    "bg1": "BG_COLOR_ALPHA",
    "txt1": "TEXT_COLOR",
    "txtError": "TEXT_COLOR",
    "bg3": "BG_COLOR_ALPHA",
    "txt4": "TEXT_COLOR",
    "fnt2": "FONT",
    "bg2": "BG_COLOR_ALPHA",
    "txt2": "TEXT_COLOR",
    "txtSuccess": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "fnt": "font_7",
    "bg1": "color_11",
    "txt1": "color_15",
    "txtError": "#8b0000",
    "bg3": "color_11",
    "txt4": "color_15",
    "fnt2": "font_7",
    "bg2": "color_18",
    "txt2": "color_15",
    "txtSuccess": "#bada55"
  },
  "css": {
    "%wrapper": "min-width:180px;max-width:980px;position:relative;width:100%;",
    "%_hiddenField": "display:none !important;",
    "%[data-state~=\"right\"]": "direction:rtl;text-align:right;",
    "%[data-state~=\"left\"]": "direction:ltr;text-align:left;",
    "%wrapper > %_flex-wrapper": "display:-webkit-box;display:-webkit-flex;display:flex;",
    "%[data-state~=\"mobile\"] %wrapper > %_flex-wrapper": "display:block;",
    "%wrapper > %_flex-wrapper %_input-container": "-webkit-box-flex:1;-webkit-flex:1;flex:1;min-width:0;",
    "%wrapper %_input-container": "position:relative;width:100%;height:100%;overflow:hidden;display:block;margin-bottom:12px;",
    "%[data-state~=\"mobile\"] %wrapper %_input-container": "margin-bottom:10px;",
    "%wrapper %_input-container > %_input": "-webkit-appearance:none;-moz-appearance:none;[fnt]      background-color:[bg1];color:[txt1];position:absolute;padding-left:15px;border:0;width:80%;-webkit-transition:-webkit-transform 0.5s;transition:-webkit-transform 0.5s;transition:transform 0.5s;transition:transform 0.5s, -webkit-transform 0.5s;right:100%;",
    "%wrapper %_input-container > %_input:focus,%wrapper %_input-container > %_input%_value": "-webkit-transform:translateX(100%);transform:translateX(100%);",
    "%wrapper %_input-container > %_input:focus + %_input-cover,%wrapper %_input-container > %_input:focus + %_text-area-cover,%wrapper %_input-container > %_input%_value + %_input-cover,%wrapper %_input-container > %_input%_value + %_text-area-cover": "-webkit-transform:translateX(80%);transform:translateX(80%);",
    "%wrapper %_input-container > %_input:focus + %_input-cover%field2-cover,%wrapper %_input-container > %_input:focus + %_input-cover%field1-cover,%wrapper %_input-container > %_input:focus + %_text-area-cover%field2-cover,%wrapper %_input-container > %_input:focus + %_text-area-cover%field1-cover,%wrapper %_input-container > %_input%_value + %_input-cover%field2-cover,%wrapper %_input-container > %_input%_value + %_input-cover%field1-cover,%wrapper %_input-container > %_input%_value + %_text-area-cover%field2-cover,%wrapper %_input-container > %_input%_value + %_text-area-cover%field1-cover": "-webkit-transform:translateX(59%);transform:translateX(59%);",
    "%[data-state~=\"mobile\"] %wrapper %_input-container > %_input": "font-size:14px;",
    "%[data-state~=\"right\"] %wrapper %_input-container > %_input": "padding-right:15px;padding-left:0;right:-80%;",
    "%[data-state~=\"right\"] %wrapper %_input-container > %_input:focus,%[data-state~=\"right\"] %wrapper %_input-container > %_input%_value": "-webkit-transform:translateX(-100%);transform:translateX(-100%);",
    "%[data-state~=\"right\"] %wrapper %_input-container > %_input:focus + %_input-cover,%[data-state~=\"right\"] %wrapper %_input-container > %_input:focus + %_text-area-cover,%[data-state~=\"right\"] %wrapper %_input-container > %_input%_value + %_input-cover,%[data-state~=\"right\"] %wrapper %_input-container > %_input%_value + %_text-area-cover": "-webkit-transform:translateX(-80%);transform:translateX(-80%);",
    "%[data-state~=\"right\"] %wrapper %_input-container > %_input:focus + %_input-cover%field2-cover,%[data-state~=\"right\"] %wrapper %_input-container > %_input:focus + %_input-cover%field1-cover,%[data-state~=\"right\"] %wrapper %_input-container > %_input:focus + %_text-area-cover%field2-cover,%[data-state~=\"right\"] %wrapper %_input-container > %_input:focus + %_text-area-cover%field1-cover,%[data-state~=\"right\"] %wrapper %_input-container > %_input%_value + %_input-cover%field2-cover,%[data-state~=\"right\"] %wrapper %_input-container > %_input%_value + %_input-cover%field1-cover,%[data-state~=\"right\"] %wrapper %_input-container > %_input%_value + %_text-area-cover%field2-cover,%[data-state~=\"right\"] %wrapper %_input-container > %_input%_value + %_text-area-cover%field1-cover": "-webkit-transform:translateX(-59%);transform:translateX(-59%);",
    "%[data-state~=\"field1Hidden\"] %wrapper %_input-container > %_input:focus + %_input-cover,%[data-state~=\"field1Hidden\"] %wrapper %_input-container > %_input%_value + %_input-cover": "-webkit-transform:translateX(80%);transform:translateX(80%);",
    "%[data-state~=\"field1Hidden\"] %wrapper %_input-container > %_input:focus + %_input-cover%field2-cover,%[data-state~=\"field1Hidden\"] %wrapper %_input-container > %_input%_value + %_input-cover%field2-cover": "-webkit-transform:translateX(80%);transform:translateX(80%);",
    "%[data-state~=\"field1Hidden\"][data-state~=\"right\"] %wrapper %_input-container > %_input:focus + %_input-cover,%[data-state~=\"field1Hidden\"][data-state~=\"right\"] %wrapper %_input-container > %_input%_value + %_input-cover": "-webkit-transform:translateX(-80%);transform:translateX(-80%);",
    "%[data-state~=\"field1Hidden\"][data-state~=\"right\"] %wrapper %_input-container > %_input:focus + %_input-cover%field2-cover,%[data-state~=\"field1Hidden\"][data-state~=\"right\"] %wrapper %_input-container > %_input%_value + %_input-cover%field2-cover": "-webkit-transform:translateX(-80%);transform:translateX(-80%);",
    "%wrapper %_input-container > %_input:placeholder": "color:#fff;opacity:0;",
    "%wrapper %_input-container > %_input:focus": "outline:0;",
    "%wrapper %_input-container > %_input%field1,%wrapper %_input-container > %_input%field2": "width:60%;",
    "%[data-state~=\"right\"] %wrapper %_input-container > %_input%field1,%[data-state~=\"right\"] %wrapper %_input-container > %_input%field2": "right:-60%;",
    "%[data-state~=\"field1Hidden\"] %wrapper %_input-container > %_input%field2": "width:80%;",
    "%[data-state~=\"field1Hidden\"][data-state~=\"right\"] %wrapper %_input-container > %_input%field2": "right:-80%;",
    "%wrapper %_input-container > %_input%_error + %_input-cover > %_label,%wrapper %_input-container > %_input%_error + %_text-area-cover > %_label": "color:[txtError];",
    "%wrapper %_input-container > textarea%_input": "resize:none;overflow-y:auto;margin:0 0 10px;height:150px;line-height:36px;",
    "%wrapper %_input-container > input%_input": "display:block;top:0;height:100%;line-height:36px;",
    "%wrapper %_input-container > %_input-cover,%wrapper %_input-container > %_text-area-cover": "display:block;position:relative;width:100%;height:100%;line-height:36px;color:#fff;cursor:text;font-size:13px;letter-spacing:3px;-webkit-transition:-webkit-transform 0.5s;transition:-webkit-transform 0.5s;transition:transform 0.5s;transition:transform 0.5s, -webkit-transform 0.5s;background-color:[bg3];",
    "%wrapper %_input-container > %_input-cover > %_label,%wrapper %_input-container > %_text-area-cover > %_label": "[fnt]        padding-left:15px;color:[txt4];",
    "%[data-state~=\"right\"] %wrapper %_input-container > %_input-cover > %_label,%[data-state~=\"right\"]        %wrapper %_input-container > %_text-area-cover > %_label": "padding-right:15px;",
    "%wrapper %_input-container > %_text-area-cover": "height:150px;",
    "%wrapper > %_button-wrapper": "position:relative;text-align:right;",
    "%[data-state~=\"right\"] %wrapper > %_button-wrapper": "position:relative;text-align:left;",
    "%wrapper > %_button-wrapper > %_submit-button": "[fnt2]      background-color:[bg2];color:[txt2];cursor:pointer;border:0;min-width:20%;max-width:calc(50% - 30px);min-height:36px;margin:0 auto;padding:15px;",
    "%[data-state~=\"right\"] %wrapper > %_button-wrapper > %_submit-button": "right:80%;",
    "%wrapper > %notifications": "font:normal 14px 'Helvetica Neue', Helvetica, sans-serif;max-width:50%;position:relative;top:-28px;left:15px;height:14px;",
    "%wrapper > %notifications%_success": "color:[txtSuccess];",
    "%wrapper > %notifications%_error": "color:[txtError];",
    "%[data-state~=\"right\"] %wrapper > %notifications": "left:-15px;"
  }
}
 skins['contactform.FullWidthButtonSkin'] = {
  "react": [
    [
      "form",
      "form-wrapper",
      [],
      {
        "role": "form"
      },
      [
        "div",
        "wrapper",
        [],
        {},
        [
          "div",
          "flex-wrapper",
          [],
          {},
          [
            "div",
            "field1-hook",
            [],
            {}
          ],
          [
            "div",
            "field2-hook",
            [],
            {}
          ]
        ],
        [
          "div",
          "fieldN-hook",
          [],
          {}
        ],
        [
          "textarea",
          "fieldMessage",
          [],
          {}
        ],
        [
          "button",
          "submit",
          [],
          {
            "type": "submit"
          }
        ],
        [
          "div",
          "notifications",
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "shd": "BOX_SHADOW",
    "fnt": "FONT",
    "bg1": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "txt1": "TEXT_COLOR",
    "txtError": "BORDER_COLOR",
    "txt3": "TEXT_COLOR",
    "bg2": "BG_COLOR_ALPHA",
    "txt2": "TEXT_COLOR",
    "fnt2": "FONT",
    "txtSuccess": "TEXT_COLOR",
    "maxbrw": "SIZE"
  },
  "paramsDefaults": {
    "shd": "0 0px 0px rgba(0, 0, 0, 0)",
    "fnt": "font_7",
    "bg1": "color_11",
    "brw": "1",
    "brd": "color_18",
    "txt1": "color_15",
    "txtError": "#8b0000",
    "txt3": "color_15",
    "bg2": "color_18",
    "txt2": "color_15",
    "fnt2": "font_7",
    "txtSuccess": "#bada55",
    "maxbrw": [
      "brw"
    ]
  },
  "paramsMutators": {
    "maxbrw": {
      "type": "max",
      "value": 1,
      "param": "brw"
    }
  },
  "css": {
    "%wrapper": "min-width:180px;max-width:980px;position:relative;width:100%;text-align:center;",
    "%_hiddenField": "display:none !important;",
    "%flex-wrapper": "display:-webkit-box;display:-webkit-flex;display:flex;",
    "%flex-wrapper input": "-webkit-box-flex:1;-webkit-flex:1;flex:1;min-width:0;",
    "%[data-state~=\"field1Hidden\"] %flex-wrapper input:nth-child(2)": "margin-left:0;",
    "% input,% textarea": "[shd]  -webkit-appearance:none;-moz-appearance:none;padding:5px;[fnt]  background-color:[bg1];-webkit-appearance:none;border:[brw] solid [brd];color:[txt1];margin:0 0 5px;width:100%;min-height:36px;box-sizing:border-box;border-radius:0;",
    "% input%_error,% textarea%_error": "[fnt]    border:[maxbrw] solid [txtError];color:[txtError];",
    "% input::-webkit-input-placeholder,% textarea::-webkit-input-placeholder": "color:[txt3];",
    "% input::-ms-input-placeholder,% textarea::-ms-input-placeholder": "color:[txt3];",
    "% input::placeholder,% textarea::placeholder": "color:[txt3];",
    "% input": "margin-bottom:12px;",
    "% textarea": "resize:none;overflow:hidden;margin:0 0 10px;min-height:150px;overflow-y:auto;",
    "%submit": "border:none;cursor:pointer;background-color:[bg2];color:[txt2];width:100%;min-height:45px;display:block;margin-bottom:15px;[fnt2]  padding:5px;",
    "%notifications": "font:normal 14px 'Helvetica Neue', Helvetica, sans-serif;max-width:60%;margin:0 auto;height:14px;",
    "%notifications%_success": "color:[txtSuccess];",
    "%notifications%_error": "color:[txtError];",
    "%[data-state~=\"mobile\"] %flex-wrapper": "-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;min-width:0;",
    "%[data-state~=\"mobile\"] %flex-wrapper input:first-child": "margin-right:0;",
    "%[data-state~=\"mobile\"] input,%[data-state~=\"mobile\"] textarea": "font-size:14px;margin-bottom:15px;",
    "%[data-state~=\"mobile\"] input::-webkit-input-placeholder,%[data-state~=\"mobile\"] textarea::-webkit-input-placeholder": "font-size:14px;",
    "%[data-state~=\"mobile\"] input::-ms-input-placeholder,%[data-state~=\"mobile\"] textarea::-ms-input-placeholder": "font-size:14px;",
    "%[data-state~=\"mobile\"] input::placeholder,%[data-state~=\"mobile\"] textarea::placeholder": "font-size:14px;",
    "%[data-state~=\"mobile\"] input": "min-height:35px;line-height:35px;",
    "%[data-state~=\"mobile\"] textarea": "min-height:150px;",
    "%[data-state~=\"right\"]": "direction:rtl;text-align:right;",
    "%[data-state~=\"right\"] %flex-wrapper input": "-webkit-box-flex:1;-webkit-flex:1;flex:1;min-width:0;",
    "%[data-state~=\"right\"] %flex-wrapper input:nth-child(1)": "margin-left:17px;margin-right:0;",
    "%[data-state~=\"right\"] %flex-wrapper input:nth-child(2)": "margin-left:0;",
    "%[data-state~=\"right\"] %flex-wrapper input:only-of-type": "margin-left:0;margin-right:0;",
    "%[data-state~=\"left\"]": "direction:ltr;text-align:left;",
    "%[data-state~=\"left\"] %flex-wrapper input": "-webkit-box-flex:1;-webkit-flex:1;flex:1;min-width:0;",
    "%[data-state~=\"left\"] %flex-wrapper input:nth-child(1)": "margin-left:0;margin-right:17px;",
    "%[data-state~=\"left\"] %flex-wrapper input:nth-child(2)": "margin-left:0;",
    "%[data-state~=\"left\"] %flex-wrapper input:only-of-type": "margin-left:0;margin-right:0;"
  }
}
 skins['contactform.LineOnlySkin'] = {
  "react": [
    [
      "form",
      "form-wrapper",
      [],
      {
        "role": "form"
      },
      [
        "div",
        "wrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_first-row-wrapper"
          ],
          {},
          [
            "div",
            "field1-hook",
            [],
            {}
          ],
          [
            "div",
            "field2-hook",
            [],
            {}
          ]
        ],
        [
          "div",
          "fieldN-hook",
          [],
          {}
        ],
        [
          "textarea",
          "fieldMessage",
          [],
          {
            "placeholder": "Message"
          }
        ],
        [
          "button",
          "submit",
          [],
          {
            "type": "submit"
          }
        ],
        [
          "div",
          "notifications",
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "brd": "BORDER_COLOR_ALPHA",
    "txt1": "TEXT_COLOR",
    "fnt": "FONT",
    "brd2": "BORDER_COLOR_ALPHA",
    "txtError": "BORDER_COLOR",
    "txt3": "TEXT_COLOR",
    "txt4": "TEXT_COLOR",
    "fnt2": "FONT",
    "txtSuccess": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "brd": "color_15",
    "txt1": "color_15",
    "fnt": "font_7",
    "brd2": "color_18",
    "txtError": "#FF0000",
    "txt3": "color_15",
    "txt4": "color_15",
    "fnt2": "font_7",
    "txtSuccess": "#bada55"
  },
  "css": {
    "%wrapper": "min-width:180px;max-width:980px;position:relative;width:100%;text-align:center;",
    "%_hiddenField": "display:none !important;",
    "%_first-row-wrapper": "display:-webkit-box;display:-webkit-flex;display:flex;",
    "%_first-row-wrapper input": "-webkit-box-flex:1;-webkit-flex:1;flex:1;min-width:0;",
    "%_first-row-wrapper input:first-child": "margin-right:30px;",
    "% input,% textarea": "padding:5px;border:none;border-bottom:1px solid [brd];color:[txt1];margin:0 0 5px;width:100%;-webkit-transition:0.5s ease all;transition:0.5s ease all;background-color:transparent;-webkit-appearance:none;-moz-appearance:none;[fnt]  -webkit-appearance:none;border-radius:0;",
    "% input:focus,% textarea:focus": "outline:none;border-color:[brd2];",
    "% input%_error,% textarea%_error": "[fnt]    color:[txtError];border-bottom:1px solid [txtError];",
    "% input%_error::-webkit-input-placeholder,% textarea%_error::-webkit-input-placeholder": "color:[txtError];",
    "% input%_error::-ms-input-placeholder,% textarea%_error::-ms-input-placeholder": "color:[txtError];",
    "% input%_error::placeholder,% textarea%_error::placeholder": "color:[txtError];",
    "% input::-webkit-input-placeholder,% textarea::-webkit-input-placeholder": "color:[txt3];",
    "% input::-ms-input-placeholder,% textarea::-ms-input-placeholder": "color:[txt3];",
    "% input::placeholder,% textarea::placeholder": "color:[txt3];",
    "% input": "margin-bottom:12px;",
    "% textarea": "resize:none;overflow:hidden;margin:0 0 10px;",
    "%fieldMessage": "min-height:128px;",
    "%submit": "color:[txt4];[fnt2]",
    "%submit:hover": "cursor:pointer;",
    "%notifications": "font:normal 14px 'Helvetica Neue', Helvetica, sans-serif;margin-top:20px;height:14px;",
    "%notifications%_success": "color:[txtSuccess];",
    "%notifications%_error": "color:[txtError];",
    "%[data-state~=\"mobile\"] input,%[data-state~=\"mobile\"] textarea": "font-size:14px;",
    "%[data-state~=\"mobile\"] input::-webkit-input-placeholder,%[data-state~=\"mobile\"] textarea::-webkit-input-placeholder": "font-size:14px;",
    "%[data-state~=\"mobile\"] input::-ms-input-placeholder,%[data-state~=\"mobile\"] textarea::-ms-input-placeholder": "font-size:14px;",
    "%[data-state~=\"mobile\"] input::placeholder,%[data-state~=\"mobile\"] textarea::placeholder": "font-size:14px;",
    "%[data-state~=\"mobile\"] input": "min-height:35px;line-height:35px;margin-bottom:12px;",
    "%[data-state~=\"mobile\"] textarea": "min-height:110px;",
    "%[data-state~=\"right\"]": "direction:rtl;text-align:right;",
    "%[data-state~=\"right\"] input": "-webkit-box-flex:1;-webkit-flex:1;flex:1;min-width:0;",
    "%[data-state~=\"right\"] input:first-child": "margin-right:0;margin-left:30px;",
    "%[data-state~=\"left\"]": "direction:ltr;text-align:left;"
  }
}
 skins['contactform.OverlappingButtonSkin'] = {
  "react": [
    [
      "form",
      "form-wrapper",
      [],
      {
        "role": "form"
      },
      [
        "div",
        "wrapper",
        [],
        {},
        [
          "div",
          "fieldN-hook",
          [],
          {}
        ],
        [
          "div",
          "textareaContainer",
          [],
          {},
          [
            "textarea",
            "fieldMessage",
            [],
            {}
          ]
        ],
        [
          "button",
          "submit",
          [],
          {
            "type": "submit"
          }
        ],
        [
          "div",
          "notifications",
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg1": "BG_COLOR_ALPHA",
    "fnt": "FONT",
    "txt1": "TEXT_COLOR",
    "labelTxt": "TEXT_COLOR",
    "txtError": "TEXT_COLOR",
    "brw2": "BORDER_SIZE",
    "bg2": "BG_COLOR_ALPHA",
    "txt2": "TEXT_COLOR",
    "fnt2": "FONT",
    "txtSuccess": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "shd": "0 0px 0px rgba(0, 0, 0, 0)",
    "brw": "1",
    "brd": "color_15",
    "bg1": "color_11",
    "fnt": "font_7",
    "txt1": "color_15",
    "labelTxt": "color_15",
    "txtError": "#8b0000",
    "brw2": "1",
    "bg2": "color_18",
    "txt2": "color_15",
    "fnt2": "font_7",
    "txtSuccess": "#bada55"
  },
  "css": {
    "%wrapper": "min-width:180px;max-width:980px;position:relative;width:100%;text-align:center;",
    "%_hiddenField": "display:none !important;",
    "% input,%textareaContainer": "border:0;[shd]  -webkit-appearance:none;-moz-appearance:none;-webkit-appearance:none;border:[brw] solid [brd];background-color:[bg1];width:100%;",
    "% input,% textarea": "padding:5px 10px;[fnt]  color:[txt1];background-color:[bg1];box-sizing:border-box;border-radius:0;",
    "% input::-webkit-input-placeholder,% textarea::-webkit-input-placeholder": "color:[labelTxt];",
    "% input::-ms-input-placeholder,% textarea::-ms-input-placeholder": "color:[labelTxt];",
    "% input::placeholder,% textarea::placeholder": "color:[labelTxt];",
    "% textarea": "background-color:rgba(0, 0, 0, 0);resize:none;margin:0;width:calc(100% + 17px);height:85%;overflow:auto;border:none;",
    "% input": "margin-bottom:12px;",
    "% input%_error": "[fnt]    color:[txtError];",
    "% input%_error::-webkit-input-placeholder": "color:[txtError];",
    "% input%_error::-ms-input-placeholder": "color:[txtError];",
    "% input%_error::placeholder": "color:[txtError];",
    "%textareaContainer": "height:128px;padding:0;overflow:hidden;background-color:[bg1];box-sizing:border-box;",
    "% textarea%_error": "[fnt]    color:[txtError];",
    "% textarea%_error::-webkit-input-placeholder": "color:[txtError];",
    "% textarea%_error::-ms-input-placeholder": "color:[txtError];",
    "% textarea%_error::placeholder": "color:[txtError];",
    "%submit": "border:[brw2] solid [brd];cursor:pointer;background-color:[bg2];color:[txt2];min-width:150px;max-width:calc(100% - 40px);min-height:55px;line-height:55px;margin:-19px auto 15px auto;display:block;[fnt2]  padding:12px;",
    "%notifications": "font:normal 14px 'Helvetica Neue', Helvetica, sans-serif;max-width:60%;display:inline-block;margin-top:-5px;height:14px;",
    "%notifications%_success": "color:[txtSuccess];",
    "%notifications%_error": "color:[txtError];",
    "%[data-state~=\"mobile\"] input,%[data-state~=\"mobile\"] textarea": "font-size:14px;",
    "%[data-state~=\"mobile\"] input::-webkit-input-placeholder,%[data-state~=\"mobile\"] textarea::-webkit-input-placeholder": "font-size:14px;",
    "%[data-state~=\"mobile\"] input::-ms-input-placeholder,%[data-state~=\"mobile\"] textarea::-ms-input-placeholder": "font-size:14px;",
    "%[data-state~=\"mobile\"] input::placeholder,%[data-state~=\"mobile\"] textarea::placeholder": "font-size:14px;",
    "%[data-state~=\"mobile\"] input": "min-height:35px;line-height:35px;margin-bottom:12px;",
    "%[data-state~=\"mobile\"] textarea": "min-height:110px;",
    "%[data-state~=\"right\"]": "direction:rtl;text-align:right;",
    "%[data-state~=\"left\"]": "direction:ltr;text-align:left;",
    "%[data-state~=\"fieldMessageHidden\"] %submit": "margin:0 auto 15px auto;",
    "%[data-state~=\"fieldMessageHidden\"] %notifications": "margin-top:-5px;",
    "%[data-state~=\"fieldMessageHidden\"] %fieldMessage,%[data-state~=\"fieldMessageHidden\"] %textareaContainer": "display:none !important;"
  }
}
 skins['wysiwyg.viewer.skins.contactform.BasicContactFormSkin'] = {
  "react": [
    [
      "form",
      "form-wrapper",
      [],
      {
        "role": "form"
      },
      [
        "div",
        "wrapper",
        [],
        {},
        [
          "div",
          "fieldN-hook",
          [],
          {}
        ],
        [
          "textarea",
          "fieldMessage",
          [],
          {}
        ],
        [
          "div",
          null,
          [
            "_form-footer"
          ],
          {},
          [
            "button",
            "submit",
            [],
            {
              "type": "submit"
            }
          ],
          [
            "span",
            "notifications",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "params": {
    "txt1": "TEXT_COLOR",
    "txtSuccess": "TEXT_COLOR",
    "txtError": "BORDER_COLOR",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "fnt": "FONT",
    "bg1": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "labelTxt": "TEXT_COLOR",
    "bg2": "BG_COLOR_ALPHA",
    "fnt2": "FONT",
    "txt2": "TEXT_COLOR",
    "maxbrw": "SIZE"
  },
  "paramsDefaults": {
    "txt1": "color_15",
    "txtSuccess": "#bada55",
    "txtError": "#8b0000",
    "rd": "5px",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "fnt": "font_7",
    "bg1": "color_11",
    "brw": "0",
    "brd": "color_15",
    "labelTxt": "#a9a9a9",
    "bg2": "color_18",
    "fnt2": "font_7",
    "txt2": "color_15",
    "maxbrw": [
      "brw"
    ]
  },
  "paramsMutators": {
    "maxbrw": {
      "type": "max",
      "value": 1,
      "param": "brw"
    }
  },
  "css": {
    "%wrapper": "min-width:180px;max-width:980px;position:relative;width:100%;",
    "%_hiddenField": "display:none !important;",
    "%_form-footer": "display:grid;grid-template-columns:35% 5% 60%;grid-template-rows:auto 1fr;direction:ltr;",
    "% span": "color:[txt1];font:normal 14px 'Helvetica Neue', Helvetica, sans-serif;",
    "% span%_success": "color:[txtSuccess];",
    "% span%_error": "color:[txtError];",
    "% button,% input,% textarea": "[rd]  [shd]  padding:5px;",
    "% input,% textarea": "[fnt]  background-color:[bg1];-webkit-appearance:none;-moz-appearance:none;border:[brw] solid [brd];color:[txt1];margin:0 0 5px;width:100%;",
    "% input%_error,% textarea%_error": "[fnt]    border:[maxbrw] solid [txtError];color:[txtError];",
    "% input::-webkit-input-placeholder,% textarea::-webkit-input-placeholder": "color:[labelTxt];",
    "% input::-ms-input-placeholder,% textarea::-ms-input-placeholder": "color:[labelTxt];",
    "% input::placeholder,% textarea::placeholder": "color:[labelTxt];",
    "% button": "background-color:[bg2];[fnt2]  border:0;color:[txt2];cursor:pointer;margin:0;",
    "% textarea": "min-height:130px;resize:none;",
    "%[data-state~=\"mobile\"] input": "color:[txt1];font-size:16px;height:45px;line-height:45px;margin:0 0 15px;padding:0 5px;",
    "%[data-state~=\"mobile\"] input::-webkit-input-placeholder": "color:[labelTxt];font-size:16px;",
    "%[data-state~=\"mobile\"] input::-ms-input-placeholder": "color:[labelTxt];font-size:16px;",
    "%[data-state~=\"mobile\"] input::placeholder": "color:[labelTxt];font-size:16px;",
    "%[data-state~=\"mobile\"] textarea": "color:[txt1];font-size:16px;height:150px;margin:0 0 10px;",
    "%[data-state~=\"mobile\"]::-webkit-input-placeholder": "color:[labelTxt];font-size:16px;",
    "%[data-state~=\"mobile\"]::-ms-input-placeholder": "color:[labelTxt];font-size:16px;",
    "%[data-state~=\"mobile\"]::placeholder": "color:[labelTxt];font-size:16px;",
    "%[data-state~=\"right\"]": "direction:rtl;text-align:right;",
    "%[data-state~=\"right\"] %_form-footer": "grid-template-columns:35% 5% 60%;direction:ltr;",
    "%[data-state~=\"right\"] span": "grid-column:3;grid-row:1/span 2;justify-self:end;",
    "%[data-state~=\"right\"] button": "grid-column:1;grid-row:1/span 1;justify-self:start;",
    "%[data-state~=\"left\"]": "direction:ltr;text-align:left;",
    "%[data-state~=\"left\"] %_form-footer": "grid-template-columns:60% 5% 35%;",
    "%[data-state~=\"left\"] span": "grid-column:1;grid-row:1/span 2;justify-self:start;",
    "%[data-state~=\"left\"] button": "grid-column:3;grid-row:1/span 1;justify-self:end;"
  }
}
 skins['wysiwyg.viewer.skins.contactform.DefaultContactForm'] = {
  "react": [
    [
      "form",
      "form-wrapper",
      [],
      {
        "role": "form"
      },
      [
        "div",
        "wrapper",
        [],
        {},
        [
          "ul",
          null,
          [
            "_row",
            "_form"
          ],
          {},
          [
            "li",
            null,
            [
              "_col",
              "_left"
            ],
            {},
            [
              "div",
              "fieldN-hook",
              [],
              {}
            ]
          ],
          [
            "li",
            null,
            [
              "_col",
              "_right"
            ],
            {},
            [
              "textarea",
              "fieldMessage",
              [],
              {}
            ]
          ]
        ],
        [
          "div",
          null,
          [
            "_foot"
          ],
          {},
          [
            "span",
            "notifications",
            [],
            {}
          ],
          [
            "button",
            "submit",
            [],
            {
              "type": "submit"
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "hidePlaceholders": false
  },
  "params": {
    "txt1": "TEXT_COLOR",
    "txtSuccess": "TEXT_COLOR",
    "txtError": "BORDER_COLOR",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg1": "BG_COLOR_ALPHA",
    "fnt": "FONT",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "txt2": "TEXT_COLOR",
    "bg2": "BG_COLOR_ALPHA",
    "fnt2": "FONT",
    "labelTxt": "TEXT_COLOR",
    "maxbrw": "SIZE"
  },
  "paramsDefaults": {
    "txt1": "color_15",
    "txtSuccess": "#bada55",
    "txtError": "#8b0000",
    "rd": "5px",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "bg1": "color_11",
    "fnt": "font_7",
    "brw": "0",
    "brd": "color_15",
    "txt2": "color_15",
    "bg2": "color_18",
    "fnt2": "font_7",
    "labelTxt": "#a9a9a9",
    "maxbrw": [
      "brw"
    ]
  },
  "paramsMutators": {
    "maxbrw": {
      "type": "max",
      "value": 1,
      "param": "brw"
    }
  },
  "css": {
    "%wrapper": "min-width:180px;max-width:980px;position:relative;width:100%;",
    "%_hiddenField": "display:none !important;",
    "% li": "box-sizing:border-box;position:relative;",
    "% ul": "box-sizing:border-box;position:relative;",
    "%_foot": "min-height:50px;position:relative;",
    "% span": "color:[txt1];font:normal 14px 'Helvetica Neue', Helvetica, sans-serif;position:absolute;top:0;",
    "% span%_success": "color:[txtSuccess];",
    "% span%_error": "color:[txtError];",
    "% button,% input,% textarea": "box-sizing:border-box;[rd]  [shd]",
    "% input,% textarea": "background-color:[bg1];[fnt]  -webkit-appearance:none;-moz-appearance:none;border:[brw] solid [brd];color:[txt1];margin:0 0 5px;padding:5px;width:100%;",
    "% input%_error,% textarea%_error": "border:[maxbrw] solid [txtError];color:[txtError];[fnt]",
    "% textarea": "height:100%;resize:none;",
    "% button": "border:0;color:[txt2];cursor:pointer;background-color:[bg2];[fnt2]  padding:5px;position:absolute;top:0;",
    "%[data-state~=\"desktop\"][data-state~=\"fieldMessageHidden\"] li": "width:100%;",
    "%[data-state~=\"desktop\"][data-state~=\"fieldMessageHidden\"] li%_right": "display:none;",
    "%[data-state~=\"desktop\"] li": "width:50%;",
    "%[data-state~=\"desktop\"][data-state~=\"left\"] li%_left": "margin:0;",
    "%[data-state~=\"desktop\"][data-state~=\"left\"] li%_right": "bottom:0;padding:0 0 5px 5px;position:absolute;right:2px;top:0;",
    "%[data-state~=\"desktop\"][data-state~=\"right\"] li%_left": "margin-left:50%;",
    "%[data-state~=\"desktop\"][data-state~=\"right\"] li%_right": "bottom:0;left:2px;padding:0 5px 5px 0;position:absolute;top:0;",
    "%[data-state~=\"mobile\"] li": "display:block;width:100%;",
    "%[data-state~=\"mobile\"] input,%[data-state~=\"mobile\"] textarea": "font-size:16px;",
    "%[data-state~=\"mobile\"] input::-webkit-input-placeholder,%[data-state~=\"mobile\"] textarea::-webkit-input-placeholder": "line-height:45px;font-size:16px;",
    "%[data-state~=\"mobile\"] input::-ms-input-placeholder,%[data-state~=\"mobile\"] textarea::-ms-input-placeholder": "line-height:45px;font-size:16px;",
    "%[data-state~=\"mobile\"] input::placeholder,%[data-state~=\"mobile\"] textarea::placeholder": "line-height:45px;font-size:16px;",
    "%[data-state~=\"mobile\"] input": "height:45px;line-height:45px;margin-bottom:15px;",
    "%[data-state~=\"mobile\"] textarea": "height:130px;",
    "% input::-webkit-input-placeholder,% textarea::-webkit-input-placeholder": "color:[labelTxt];[fnt]",
    "% input::-ms-input-placeholder,% textarea::-ms-input-placeholder": "color:[labelTxt];[fnt]",
    "% input::placeholder,% textarea::placeholder": "color:[labelTxt];[fnt]",
    "% input::-webkit-input-placeholder%_error,% textarea::-webkit-input-placeholder%_error": "color:[txtError];[fnt]",
    "% input::-ms-input-placeholder%_error,% textarea::-ms-input-placeholder%_error": "color:[txtError];[fnt]",
    "% input::placeholder%_error,% textarea::placeholder%_error": "color:[txtError];[fnt]",
    "%[data-state~=\"right\"]": "direction:rtl;text-align:right;",
    "%[data-state~=\"right\"] button": "left:0;",
    "%[data-state~=\"right\"] span": "left:100px;right:0;",
    "%[data-state~=\"left\"]": "direction:ltr;text-align:left;",
    "%[data-state~=\"left\"] button": "right:0;",
    "%[data-state~=\"left\"] span": "left:0;right:100px;"
  }
}
 skins['wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin'] = {
  "react": [
    [
      "div",
      "wrapper",
      [],
      {},
      [
        "div",
        "fieldN-hook",
        [],
        {}
      ],
      [
        "input",
        "fieldMessage",
        [],
        {}
      ],
      [
        "button",
        "submit",
        [],
        {}
      ]
    ],
    [
      "span",
      "notifications",
      [],
      {}
    ]
  ],
  "params": {
    "txt1": "TEXT_COLOR",
    "txtSuccess": "TEXT_COLOR",
    "txtError": "BORDER_COLOR",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "fnt": "FONT",
    "bg1": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "labelTxt": "TEXT_COLOR",
    "bg2": "BG_COLOR_ALPHA",
    "fnt2": "FONT",
    "txt2": "TEXT_COLOR",
    "maxbrw": "SIZE"
  },
  "paramsDefaults": {
    "txt1": "color_15",
    "txtSuccess": "#bada55",
    "txtError": "#8b0000",
    "rd": "5px",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "fnt": "font_7",
    "bg1": "color_11",
    "brw": "0",
    "brd": "color_15",
    "labelTxt": "#a9a9a9",
    "bg2": "color_18",
    "fnt2": "font_7",
    "txt2": "color_15",
    "maxbrw": [
      "brw"
    ]
  },
  "paramsMutators": {
    "maxbrw": {
      "type": "max",
      "value": 1,
      "param": "brw"
    }
  },
  "css": {
    "%wrapper": "min-width:180px;max-width:980px;position:relative;width:100%;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-direction:row;flex-direction:row;-webkit-flex-wrap:wrap;flex-wrap:wrap;",
    "%_hiddenField": "display:none !important;",
    "% button": "-webkit-box-flex:1;-webkit-flex-grow:1;flex-grow:1;background-color:[bg2];[fnt2]  border:0;color:[txt2];cursor:pointer;margin:0;",
    "%_fieldInput": "-webkit-box-flex:2;-webkit-flex-grow:2;flex-grow:2;",
    "%_message": "-webkit-box-flex:3;-webkit-flex-grow:3;flex-grow:3;",
    "% span": "color:[txt1];float:left;font:normal 14px 'Helvetica Neue', Helvetica, sans-serif;max-width:60%;margin:0 5px;",
    "% span%_success": "color:[txtSuccess];",
    "% span%_error": "color:[txtError];",
    "% button,%_fieldInput,%_message": "[rd]  [shd]  padding:5px;margin:5px;width:auto;",
    "%_fieldInput[data-dcf-columns=\"2\"]": "-webkit-flex-basis:46%;flex-basis:46%;width:50%;",
    "% button[data-dcf-columns=\"2\"]": "width:100%;",
    "%_fieldInput[data-dcf-columns=\"3\"]": "-webkit-flex-basis:30%;flex-basis:30%;width:33%;",
    "% button[data-dcf-columns=\"3\"]": "width:100%;",
    "%_fieldInput[data-dcf-columns=\"4\"]": "-webkit-flex-basis:23%;flex-basis:23%;width:25%;",
    "%_message[data-dcf-columns=\"4\"]": "width:40%;",
    "%_fieldInput,%_message": "[fnt]  background-color:[bg1];-webkit-appearance:none;-moz-appearance:none;border:[brw] solid [brd];color:[txt1];",
    "%_fieldInput%_error,%_message%_error": "[fnt]    border:[maxbrw] solid [txtError];color:[txtError];",
    "%_fieldInput::-webkit-input-placeholder,%_message::-webkit-input-placeholder": "color:[labelTxt];",
    "%_fieldInput::-ms-input-placeholder,%_message::-ms-input-placeholder": "color:[labelTxt];",
    "%_fieldInput::placeholder,%_message::placeholder": "color:[labelTxt];",
    "%[data-state~=\"mobile\"] button,%[data-state~=\"mobile\"] %_fieldInput,%[data-state~=\"mobile\"] %_message": "width:100%;-webkit-flex-basis:initial;flex-basis:initial;line-height:45px;height:45px;margin:0 0 15px;",
    "%[data-state~=\"mobile\"] %_message,%[data-state~=\"mobile\"] %_fieldInput": "color:[txt1];font-size:16px;",
    "%[data-state~=\"mobile\"] %_message::-webkit-input-placeholder,%[data-state~=\"mobile\"] %_fieldInput::-webkit-input-placeholder": "color:[labelTxt];font-size:16px;",
    "%[data-state~=\"mobile\"] %_message::-ms-input-placeholder,%[data-state~=\"mobile\"] %_fieldInput::-ms-input-placeholder": "color:[labelTxt];font-size:16px;",
    "%[data-state~=\"mobile\"] %_message::placeholder,%[data-state~=\"mobile\"] %_fieldInput::placeholder": "color:[labelTxt];font-size:16px;",
    "%[data-state~=\"mobile\"] button": "padding:0;",
    "%[data-state~=\"right\"]": "direction:rtl;text-align:right;",
    "%[data-state~=\"right\"] span": "float:right;",
    "%[data-state~=\"left\"]": "direction:ltr;text-align:left;",
    "%[data-state~=\"left\"] span": "float:left;"
  }
}
 skins['wysiwyg.viewer.skins.contactform.VerticalForm'] = {
  "react": [
    [
      "form",
      "form-wrapper",
      [],
      {
        "role": "form"
      },
      [
        "div",
        "wrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_clearfix"
          ],
          {},
          [
            "div",
            "fieldN-hook",
            [],
            {}
          ],
          [
            "label",
            "label_fieldMessage",
            [],
            {}
          ],
          [
            "textarea",
            "fieldMessage",
            [],
            {}
          ],
          [
            "div",
            null,
            [
              "_form-footer"
            ],
            {},
            [
              "button",
              "submit",
              [],
              {
                "type": "submit"
              }
            ],
            [
              "span",
              "notifications",
              [],
              {}
            ]
          ]
        ]
      ]
    ]
  ],
  "params": {
    "txt3": "TEXT_COLOR",
    "fnt": "FONT",
    "txt1": "TEXT_COLOR",
    "txtSuccess": "TEXT_COLOR",
    "txtError": "BORDER_COLOR",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg1": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "fnt2": "FONT",
    "bg2": "BG_COLOR_ALPHA",
    "txt2": "TEXT_COLOR",
    "maxbrw": "SIZE"
  },
  "paramsDefaults": {
    "txt3": "color_15",
    "fnt": "font_7",
    "txt1": "color_15",
    "txtSuccess": "#bada55",
    "txtError": "#8b0000",
    "rd": "5px",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "bg1": "color_11",
    "brw": "0",
    "brd": "color_15",
    "fnt2": "font_7",
    "bg2": "color_18",
    "txt2": "color_15",
    "maxbrw": [
      "brw"
    ]
  },
  "paramsMutators": {
    "maxbrw": {
      "type": "max",
      "value": 1,
      "param": "brw"
    }
  },
  "css": {
    "%wrapper": "min-width:180px;max-width:980px;position:relative;width:100%;",
    "%_hiddenField": "display:none !important;",
    "%_form-footer": "display:grid;grid-template-columns:35% 5% 60%;grid-template-rows:auto 1fr;direction:ltr;",
    "% label": "color:[txt3];display:block;[fnt]  padding:5px 0;width:100%;",
    "% span": "color:[txt1];font:normal 14px 'Helvetica Neue', Helvetica, sans-serif;",
    "% span%_success": "color:[txtSuccess];",
    "% span%_error": "color:[txtError];",
    "% button,% input,% textarea": "[rd]  [shd]",
    "% input,% textarea": "[fnt]  -webkit-appearance:none;-moz-appearance:none;background-color:[bg1];border:[brw] solid [brd];color:[txt1];display:block;padding:5px;width:100%;",
    "% input%_error,% textarea%_error": "[fnt]    border:[maxbrw] solid [txtError];color:[txtError];",
    "% input::-webkit-input-placeholder,% textarea::-webkit-input-placeholder": "color:transparent;",
    "% input::-ms-input-placeholder,% textarea::-ms-input-placeholder": "color:transparent;",
    "% input::placeholder,% textarea::placeholder": "color:transparent;",
    "% button": "[fnt2]  background-color:[bg2];border:0;color:[txt2];cursor:pointer;margin:0;padding:5px;",
    "% input": "margin:0 0 5px;",
    "% textarea": "height:130px;margin:2px 0 17px;resize:none;",
    "%[data-state~=\"mobile\"] label": "display:block;font-size:14px;margin:3px 0;padding:0;width:100%;",
    "%[data-state~=\"mobile\"] input,%[data-state~=\"mobile\"] textarea": "-webkit-appearance:none;-moz-appearance:none;display:block;font-size:14px;padding:0 5px;width:100%;",
    "%[data-state~=\"mobile\"] input": "height:45px;line-height:45px;margin:0;",
    "%[data-state~=\"mobile\"] textarea": "height:150px;margin:0 0 17px;",
    "%[data-state~=\"right\"]": "direction:rtl;text-align:right;",
    "%[data-state~=\"right\"] %_form-footer": "grid-template-columns:35% 5% 60%;direction:ltr;",
    "%[data-state~=\"right\"] span": "grid-column:3;grid-row:1/span 2;justify-self:end;",
    "%[data-state~=\"right\"] button": "grid-column:1;grid-row:1/span 1;justify-self:start;",
    "%[data-state~=\"left\"]": "direction:ltr;text-align:left;",
    "%[data-state~=\"left\"] %_form-footer": "grid-template-columns:60% 5% 35%;",
    "%[data-state~=\"left\"] span": "grid-column:1;grid-row:1/span 2;justify-self:start;",
    "%[data-state~=\"left\"] button": "grid-column:3;grid-row:1/span 1;justify-self:end;"
  }
}
 skins['wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft'] = {
  "react": [
    [
      "form",
      "form-wrapper",
      [],
      {
        "role": "form"
      },
      [
        "ul",
        "wrapper",
        [],
        {
          "data-note": "fieldN-hook"
        },
        [
          "li",
          null,
          [
            "_row"
          ],
          {},
          [
            "label",
            "label_fieldMessage",
            [
              "_col"
            ],
            {}
          ],
          [
            "div",
            null,
            [
              "_col"
            ],
            {},
            [
              "textarea",
              "fieldMessage",
              [],
              {}
            ]
          ]
        ],
        [
          "li",
          null,
          [
            "_row"
          ],
          {},
          [
            "div",
            null,
            [
              "_cell"
            ],
            {}
          ],
          [
            "div",
            null,
            [
              "_cell",
              "_msg"
            ],
            {},
            [
              "span",
              "notifications",
              [],
              {}
            ],
            [
              "button",
              "submit",
              [],
              {
                "type": "submit"
              }
            ]
          ]
        ]
      ]
    ]
  ],
  "params": {
    "txt3": "TEXT_COLOR",
    "fnt": "FONT",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg2": "BG_COLOR_ALPHA",
    "fnt2": "FONT",
    "txt2": "TEXT_COLOR",
    "txtSuccess": "TEXT_COLOR",
    "txtError": "BORDER_COLOR",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "txt1": "TEXT_COLOR",
    "bg1": "BG_COLOR_ALPHA",
    "maxbrw": "SIZE"
  },
  "paramsDefaults": {
    "txt3": "color_15",
    "fnt": "font_7",
    "rd": "5px",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "bg2": "color_18",
    "fnt2": "font_7",
    "txt2": "color_15",
    "txtSuccess": "#bada55",
    "txtError": "#8b0000",
    "brw": "0",
    "brd": "color_15",
    "txt1": "color_15",
    "bg1": "color_11",
    "maxbrw": [
      "brw"
    ]
  },
  "paramsMutators": {
    "maxbrw": {
      "type": "max",
      "value": 1,
      "param": "brw"
    }
  },
  "css": {
    "%wrapper": "min-width:180px;max-width:980px;position:relative;width:100%;",
    "%_hiddenField": "display:none !important;",
    "% label": "box-sizing:border-box;color:[txt3];[fnt]",
    "% button": "[rd]  [shd]  background-color:[bg2];[fnt2]  border:0;color:[txt2];cursor:pointer;display:inline-block;max-width:35%;padding:5px;vertical-align:top;position:absolute;right:0;white-space:nowrap;",
    "% textarea": "height:130px;resize:none;",
    "%notifications": "border:0;color:[txt2];cursor:pointer;display:inline-block;font:normal 14px \"Helvetica Neue\", Helvetica, sans-serif;max-width:60%;vertical-align:top;position:absolute;left:0;",
    "%notifications%_success": "color:[txtSuccess];",
    "%notifications%_error": "color:[txtError];",
    "% input,% textarea": "-webkit-appearance:none;-moz-appearance:none;border:[brw] solid [brd];box-sizing:border-box;color:[txt1];[rd]  [shd]  background-color:[bg1];[fnt]  padding:5px;width:100%;margin:2px 0 7px 0;",
    "% input%_error,% textarea%_error": "border:[maxbrw] solid [txtError];color:[txtError];[fnt]",
    "% input::-webkit-input-placeholder,% textarea::-webkit-input-placeholder": "color:transparent;",
    "% input::-ms-input-placeholder,% textarea::-ms-input-placeholder": "color:transparent;",
    "% input::placeholder,% textarea::placeholder": "color:transparent;",
    "%_row": "min-width:100%;",
    "%_cell": "vertical-align:top;",
    "%[data-state~=\"desktop\"] %wrapper": "display:table;vertical-align:top;",
    "%[data-state~=\"desktop\"] %_row": "display:table-row;",
    "%[data-state~=\"desktop\"] %_cell": "display:table-cell;",
    "%[data-state~=\"desktop\"] label": "display:table-cell;vertical-align:top;width:85px;",
    "%[data-state~=\"desktop\"][data-state~=\"right\"] label": "padding:0 0 5px 15px;text-align:left;",
    "%[data-state~=\"desktop\"][data-state~=\"left\"] label": "padding:0 15px 5px 0;text-align:right;",
    "%[data-state~=\"desktop\"] %_col": "display:table-cell;vertical-align:top;",
    "%[data-state~=\"mobile\"] %wrapper,%[data-state~=\"mobile\"] %_row,%[data-state~=\"mobile\"] %_cell,%[data-state~=\"mobile\"] %_col": "display:block;",
    "%[data-state~=\"mobile\"] label": "display:block;font-size:14px;margin:3px 0;padding:0;width:auto;",
    "%[data-state~=\"mobile\"] input,%[data-state~=\"mobile\"] textarea": "width:100%;",
    "%[data-state~=\"mobile\"] input": "display:block;font-size:14px;height:45px;line-height:45px;margin:0;",
    "%[data-state~=\"mobile\"] textarea": "height:150px;",
    "%[data-state~=\"mobile\"] button": "margin-top:10px;",
    "%[data-state~=\"mobile\"][data-state~=\"left\"] input": "margin:2px 0 7px;",
    "%[data-state~=\"mobile\"][data-state~=\"left\"] textarea": "margin:0 0 5px;",
    "%[data-state~=\"mobile\"][data-state~=\"right\"] input": "margin:2px 0 7px 0;",
    "%[data-state~=\"mobile\"][data-state~=\"right\"] textarea": "margin:2px 0 5px 0;",
    "%[data-state~=\"right\"]": "direction:rtl;text-align:right;",
    "%[data-state~=\"right\"] span": "padding-left:10px;",
    "%[data-state~=\"right\"] %_msg": "direction:rtl;position:relative;height:40px;",
    "%[data-state~=\"right\"] input": "margin:2px 0 7px 0;",
    "%[data-state~=\"right\"] textarea": "margin:2px 0 5px 0;",
    "%[data-state~=\"left\"]": "direction:ltr;text-align:left;",
    "%[data-state~=\"left\"] span": "padding-right:10px;",
    "%[data-state~=\"left\"] %_msg": "direction:ltr;position:relative;height:40px;",
    "%[data-state~=\"left\"] input": "margin:2px 0 7px 0;",
    "%[data-state~=\"left\"] textarea": "margin:0 0 5px 0;"
  }
}

        return skins;
    }));
define([
    'lodash',
    'coreUtils',
    'formCommon',
    'skins',
    'contactForm/skins/skins.json',
    'componentsCore',
    'santa-components'
],
function (
    _,
    coreUtils,
    formCommon,
    skinsPackage,
    skinsJson,
    componentsCore,
    santaComponents
) {
    'use strict';

    function createFormNotifications() {
        const notificationsState = this.state.notifications;
        return {
            notifications: {
                'aria-live': 'polite',
                className: this.classSet({
                    error: notificationsState.error,
                    success: !notificationsState.error
                }),
                children: [
                    notificationsState.message
                ]
            }
        };
    }

    const SUPPORTED_LANGS = ['da', 'de', 'en', 'es', 'fr', 'hi', 'it', 'ja', 'ko', 'nl', 'no', 'pl', 'pt', 'ru', 'sv', 'tr'];

    const FIELD_TYPE_TO_ACTIVITY_TYPE = {
        name: 'firstName',
        secondaryEmail: 'email',
        customField: 'additional'
    };

    function getLanguage(dataLang, userLanguage) {
        if (dataLang === 'userLang') {
            return userLanguage;
        }
        return dataLang;
    }


    function validateField(fieldState, skinId, fieldValue) {
        const validations = coreUtils.validationUtils;
        if (!fieldState.hidden && fieldState.required) {
            return skinId === getEmailFieldSkinId.call(this) ? validations.isValidEmail(fieldValue) : !_.isEmpty(fieldValue);
        }
        return true;
    }

    function getFormNewState(state, skinId, fieldValue, isValid) {
        if (isValid) {
            return createValidInputCompState(state, skinId, fieldValue);
        }
        return createInvalidInputCompState.call(this, skinId, state[skinId]);
    }

    function allInputsValid(state) {
        return _.reduce(_.keys(state), function (result, field) {
            return field === 'notification' ? result : result && field;
        }, true);
    }

    function createValidInputCompState(state, fieldName, fieldValue) {
        const newState = {};
        const fieldState = state[fieldName];
        if (fieldState && fieldState.error) {
            newState[fieldName] = {
                error: true
            };
            if (allInputsValid(state)) {
                newState.notifications = {
                    error: false,
                    message: ''
                };
            }
        }
        newState[fieldName] = _.assign(newState[fieldName] || {}, {
            hidden: fieldState.hidden,
            required: fieldState.required,
            value: fieldValue
        });
        return newState;
    }

    function createInvalidInputCompState(skinId, fieldState) {
        const newState = {};
        newState[skinId] = {
            error: true,
            hidden: fieldState.hidden,
            required: fieldState.required,
            value: fieldState.value
        };
        newState.notifications = {
            message: skinId === getEmailFieldSkinId.call(this) ? this.props.errorMessage : this.props.validationErrorMessage,
            error: true
        };
        return newState;
    }

    function onFormInputClick(event) {
        const fieldSkinId = event.target.id.replace(this.props.id, '');
        this.setState(_.assign(this.state[fieldSkinId], {error: false}));
    }

    function onFormInputChange(event) {
        const fieldSkinId = event.target.id.replace(this.props.id, '');
        this.setState(_.assign(this.state[fieldSkinId], {value: event.target.value, error: false}));
    }

    function registerInputRef(ref) {
        if (ref !== null) {
            _.set(this, ['refsToInputComps', ref.id], ref);
        }
    }

    function createFieldsCSSStates(fields) {
        const allFields = _.find(fields, {fieldType: 'message'}) ? fields : fields.concat({fieldType: 'message', hidden: false});
        return _.reduce(allFields, function (result, field) {
            const isFieldHidden = !field.hidden;
            const skinId = createFieldSkinId(field);
            result[`$${skinId}`] = isFieldHidden ? `${skinId}Hidden` : '';
            return result;
        }, {});
    }

    function getEmailFieldSkinId() {
        const emailField = _.find(this.props.orderedFields, {fieldType: 'email'});
        return emailField ? createFieldSkinId(emailField) : '';
    }

    function getEmailFieldState() {
        return this.state[getEmailFieldSkinId.call(this)];
    }

    function getNameFieldState() {
        const nameField = _.find(this.props.orderedFields, {fieldType: 'name'});
        return nameField ? this.state[createFieldSkinId(nameField)] : '';
    }

    function createFieldSkinId(field) {
        if (field.fieldType === 'message') {
            return 'fieldMessage';
        }
        return `field${field.index + 1}`;
    }

    function createLabelSkinId(field) {
        if (field.fieldType === 'message') {
            return 'label_fieldMessage';
        }
        return `label_field${field.index + 1}`;
    }

    function createContainerSkinId(field) {
        if (field.fieldType === 'message') {
            return 'fieldMessageContainer';
        }
        return `field${field.index + 1}Container`;
    }

    function createFieldLabel(field, isDynamicContactForm) {
        if (isDynamicContactForm && field.fieldLabel) {
            return field.fieldLabel + (field.required ? ' *' : '');
        }
        return field.fieldLabel;
    }

    function createFieldSkinProperty(skinId, field) {
        const fieldState = this.state[skinId] || {};
        const stateBasedOnProps = {
            required: !!field.required,
            hidden: !field.hidden, // TODO - remove (probably not in use)
            hiddenField: !field.hidden
        };
        _.merge(fieldState, stateBasedOnProps);
        return {
            skinId,
            fieldType: field.fieldType,
            name: field.fieldLabel,
            value: fieldState.value ? fieldState.value : '',
            required: field.required,
            className: this.classSet(fieldState),
            placeholder: createFieldLabel(field, this.props.isDynamicContactForm),
            onChange: onFormInputChange.bind(this),
            onClick: onFormInputClick.bind(this),
            'data-aid': `${field.fieldType}Field`
        };
    }

    function createFieldsInputsSkinProps() {
        return this.props.orderedFields.reduce(function (result, field) {
            const skinId = createFieldSkinId(field);
            const props = createFieldSkinProperty.call(this, skinId, field);
            return result.push(props) && result;
        }.bind(this), []);
    }

    function createFieldsLabelsSkinProps() {
        return this.props.orderedFields.reduce(function (result, field) {
            const isHidden = !field.hidden;
            const labelSkinProps = {
                skinId: createLabelSkinId(field),
                className: this.classSet({
                    hidden: isHidden,
                    hiddenField: isHidden
                }),
                children: [createFieldLabel(field, this.props.isDynamicContactForm)],
                'data-aid': `label_${field.fieldType}`
            };
            return result.push(labelSkinProps) && result;
        }.bind(this), []);
    }

    function createFieldsContainersSkinProps() {
        if (this.props.skin !== 'contactform.FieldAnimationSkin') {
            return [];
        }
        return this.props.orderedFields.reduce(function (result, field) {
            const props = {
                skinId: createContainerSkinId(field),
                className: this.classSet({hiddenField: !field.hidden})
            };
            return result.push(props) && result;
        }.bind(this), []);
    }

    function mergeWithClassName(dest, src) {
        return _.mergeWith(dest, src, function (objValue, srcValue, key) {
            if (key === 'className') {
                return `${objValue || ''} ${srcValue || ''}`.trim();
            }
        });
    }

    function createRequiredState(inputProps) {
        return inputProps.required ? 'true' : null;
    }

    function createAriaInvalid(fieldSkinId) {
        if (this.state[fieldSkinId]) {
            return this.state[fieldSkinId].error ? 'true' : 'false';
        }
        return 'false';
    }

    function createFieldType(inputProps) {
        return inputProps.fieldType === 'phone' ? 'tel' : 'text';
    }

    function createFieldAnimationSkinDOM(fieldIndex, inputProps, labelProps, containerProps) {
        const fieldSkinId = createFieldSkinId({index: fieldIndex});
        const cover = santaComponents.utils.createReactElement('span', mergeWithClassName({
            id: createLabelSkinId({index: fieldIndex}),
            className: this.classSet({'label': true})
        }, labelProps));
        const labelCover = santaComponents.utils.createReactElement('div', {
            id: `field${fieldIndex + 1}-cover`,
            className: this.classSet({'input-cover': true})
        }, cover);
        const input = santaComponents.utils.createReactElement('input', mergeWithClassName({
            id: fieldSkinId,
            ref: registerInputRef.bind(this),
            type: createFieldType(inputProps),
            className: this.classSet({'input': true}),
            required: createRequiredState(inputProps),
            'aria-invalid': createAriaInvalid.call(this, fieldSkinId)
        }, inputProps));
        return santaComponents.utils.createReactElement('label', mergeWithClassName({
            id: createContainerSkinId({index: fieldIndex}),
            className: this.classSet({'input-container': true})
        }, containerProps), input, labelCover);
    }

    function createVerticalFormSkinDOM(fieldIndex, inputProps, labelProps) {
        const fieldSkinId = createFieldSkinId({index: fieldIndex});
        const label = santaComponents.utils.createReactElement('label', _.merge({
            id: createLabelSkinId({index: fieldIndex}),
            htmlFor: fieldSkinId
        }, labelProps), labelProps.children[0]);
        const input = santaComponents.utils.createReactElement('input', _.merge({
            id: fieldSkinId,
            ref: registerInputRef.bind(this),
            type: createFieldType(inputProps),
            required: createRequiredState(inputProps),
            'aria-invalid': createAriaInvalid.call(this, fieldSkinId)
        }, inputProps));
        return santaComponents.utils.createReactElement('div', null, [label, input]);
    }

    function createVerticalFormLabelsSkinDOM(fieldIndex, inputProps, labelProps) {
        const colClassName = this.classSet({'col': true});
        const fieldSkinId = createFieldSkinId({index: fieldIndex});
        const label = santaComponents.utils.createReactElement('label', _.merge({
            id: createLabelSkinId({index: fieldIndex}),
            htmlFor: fieldSkinId,
            className: colClassName
        }, labelProps), labelProps.children[0]);
        const input = santaComponents.utils.createReactElement('input', _.merge({
            id: fieldSkinId,
            ref: registerInputRef.bind(this),
            type: createFieldType(inputProps),
            required: createRequiredState(inputProps),
            'aria-invalid': createAriaInvalid.call(this, fieldSkinId)
        }, inputProps));
        const inputWrapper = santaComponents.utils.createReactElement('div', {
            className: colClassName
        }, input);
        return santaComponents.utils.createReactElement('li', {
            className: this.classSet({'row': true})
        }, [label, inputWrapper]);
    }

    function createFieldInputSkinDOM(fieldIndex, inputProps) {
        const fieldSkinId = createFieldSkinId({index: fieldIndex});
        return santaComponents.utils.createReactElement('input', mergeWithClassName({
            id: fieldSkinId,
            ref: registerInputRef.bind(this),
            key: fieldSkinId,
            type: createFieldType(inputProps),
            required: createRequiredState(inputProps),
            'aria-invalid': createAriaInvalid.call(this, fieldSkinId)
        }, inputProps));
    }

    function createHorizontalFieldInputSkinDOM(fieldIndex, inputProps) {
        const classObject = {};
        classObject.fieldInput = true;
        const fieldSkinId = createFieldSkinId({index: fieldIndex});
        return santaComponents.utils.createReactElement('input', mergeWithClassName({
            id: fieldSkinId,
            ref: registerInputRef.bind(this),
            key: fieldSkinId,
            type: createFieldType(inputProps),
            className: this.classSet(classObject),
            required: createRequiredState(inputProps),
            'aria-invalid': createAriaInvalid.call(this, fieldSkinId)
        }, inputProps));
    }

    function createFieldAnimationSkinProps(fieldsData) {
        const self = this;
        const res = {'field1-hook': createFieldAnimationSkinDOM.call(self, 0, fieldsData[0].input, fieldsData[0].label, fieldsData[0].container)};
        if (fieldsData[1]) {
            _.assign(res, {'field2-hook': createFieldAnimationSkinDOM.call(self, 1, fieldsData[1].input, fieldsData[1].label, fieldsData[1].container)});
            return _.assign(res, {
                'fieldN-hook': santaComponents.utils.createReactElement('div', null, ..._.map(fieldsData.slice(2), function (input, index) {
                    const i = index + 2;
                    return createFieldAnimationSkinDOM.call(self, i, fieldsData[i].input, fieldsData[i].label, fieldsData[i].container);
                }))
            });
        }
        return res;
    }

    function createTopTwoFieldsSkinProps(fieldsData) {
        const self = this;
        const res = {'field1-hook': createFieldInputSkinDOM.call(self, 0, fieldsData[0].input)};
        if (fieldsData[1]) {
            _.assign(res, {'field2-hook': createFieldInputSkinDOM.call(self, 1, fieldsData[1].input)});
            return _.assign(res, {
                'fieldN-hook': santaComponents.utils.createReactElement('div', null, _.map(fieldsData.slice(2), function (input, index) {
                    const i = index + 2;
                    return createFieldInputSkinDOM.call(self, i, fieldsData[i].input);
                }))
            });
        }

        return res;
    }

    function createSimpleFieldsSkinProps(fieldsData) {
        const self = this;
        return {
            'fieldN-hook': santaComponents.utils.createReactElement('div', null, _.map(fieldsData, function (input, index) {
                return createFieldInputSkinDOM.call(self, index, fieldsData[index].input);
            }))
        };
    }

    function createVerticalFormSkinProps(fieldsData) {
        const self = this;
        return {
            'fieldN-hook': santaComponents.utils.createReactElement('div', null, _.map(fieldsData, function (input, index) {
                return createVerticalFormSkinDOM.call(self, index, fieldsData[index].input, fieldsData[index].label);
            }))
        };
    }

    function createHorizontalFormSkinProps(fieldsData) {
        const self = this;
        return {
            'wrapper': {addChildrenBefore: _.map(fieldsData, function (input, index) {
                return createHorizontalFieldInputSkinDOM.call(self, index, fieldsData[index].input);
            })}
        };
    }

    function createVerticalFormLabelsLeftSkinProps(fieldsData) {
        const self = this;
        return {
            'wrapper': {addChildrenBefore: _.map(fieldsData, function (input, index) {
                return createVerticalFormLabelsSkinDOM.call(self, index, fieldsData[index].input, fieldsData[index].label);
            })}
        };
    }

    function createFieldsSkinProps(fieldsData) { // eslint-disable-line complexity
        switch (this.props.skin) {
            case 'contactform.FieldAnimationSkin':
                return createFieldAnimationSkinProps.call(this, fieldsData);
            case 'contactform.FullWidthButtonSkin':
            case 'contactform.LineOnlySkin':
                return createTopTwoFieldsSkinProps.call(this, fieldsData);
            case 'contactform.OverlappingButtonSkin':
            case 'wysiwyg.viewer.skins.contactform.BasicContactFormSkin':
            case 'wysiwyg.viewer.skins.contactform.DefaultContactForm':
                return createSimpleFieldsSkinProps.call(this, fieldsData);
            case 'wysiwyg.viewer.skins.contactform.VerticalForm':
                return createVerticalFormSkinProps.call(this, fieldsData);
            case 'wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft':
                return createVerticalFormLabelsLeftSkinProps.call(this, fieldsData);
            case 'wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin':
                return createHorizontalFormSkinProps.call(this, fieldsData);
            default:
                return {};
        }
    }

    function hasMessageField(fieldsData) {
        return _.get(_.last(fieldsData), ['input', 'skinId']) === 'fieldMessage';
    }

    function removeMessageFieldData(fieldsData) {
        return fieldsData.splice(-1)[0];
    }

    function createFieldsData() {
        return _.zipWith(
            createFieldsInputsSkinProps.call(this),
            createFieldsLabelsSkinProps.call(this),
            createFieldsContainersSkinProps.call(this),
            function (inputData, labelData, containerData) {
                return {input: inputData, label: labelData, container: containerData};
            });
    }

    function createMessageFieldSkinProps(messageFieldData) {
        const hiddenSkinProp = {className: this.classSet({hiddenField: true})};
        const skinProps = {};
        if (this.props.skin === 'wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin') {
            const horizontalSkinColumns = {};
            horizontalSkinColumns.message = true;
            skinProps.className = this.classSet(horizontalSkinColumns);
        }
        return {
            fieldMessage: mergeWithClassName(_.get(messageFieldData, 'input', hiddenSkinProp), skinProps),
            label_fieldMessage: _.get(messageFieldData, 'label', hiddenSkinProp),
            fieldMessageContainer: _.get(messageFieldData, 'container', hiddenSkinProp)
        };
    }

    function getFormBuilderActivityFields() {
        const TAGGED_FIELDS = ['phone', 'address', 'date', 'website', 'email'];

        const createTag = field => _.includes(TAGGED_FIELDS, field.fieldType) ? {tag: 'main'} : {};
        const createField = field => ({
            value: field.fieldType === 'customField' ?
                {string: this.state[createFieldSkinId(field)].value || ''} :
                this.state[createFieldSkinId(field)].value || ''
        });

        return _.reduce(this.props.orderedFields, (result, field) => {
            const isDisplayed = field.hidden;
            if (isDisplayed) {
                result.push({
                    label: field.fieldLabel,
                    [FIELD_TYPE_TO_ACTIVITY_TYPE[field.fieldType] || field.fieldType]: _.merge({}, createTag(field), createField(field))
                });
            }
            return result;
        }, []);
    }

    function getFieldValue(skinId) {
        if (this.refsToInputComps[skinId]) {
            return this.refsToInputComps[skinId].value;
        } else if (this.refs[skinId]) {
            return this.refs[skinId].value;
        }
        return '';
    }

    const contactForm = {
        displayName: 'ContactForm',
        mixins: [formCommon.formMixin],

        propTypes: {
            orderedFields: santaComponents.santaTypesDefinitions.ContactFormSantaTypes.orderedFields.isRequired,
            errorMessage: santaComponents.santaTypesDefinitions.ContactFormSantaTypes.errorMessage.isRequired,
            validationErrorMessage: santaComponents.santaTypesDefinitions.ContactFormSantaTypes.validationErrorMessage.isRequired,
            isDynamicContactForm: santaComponents.santaTypesDefinitions.ContactFormSantaTypes.isDynamicContactForm.isRequired,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            skin: santaComponents.santaTypesDefinitions.Component.skin.isRequired,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId
        },

        componentWillReceiveProps(nextProps) {
            const previousCSSStates = createFieldsCSSStates(this.props.orderedFields);
            const newCSSStates = createFieldsCSSStates(nextProps.orderedFields);
            if (!_.isEqual(previousCSSStates, newCSSStates)) {
                this.setState(newCSSStates);
            }
        },

        /*override*/
        getFormInitialState() {
            const initial = {
                mailSent: false,
                notifications: {
                    error: false,
                    message: '',
                    hidden: true,
                    required: false
                }
            };
            const state = this.props.orderedFields.reduce(function (result, field) {
                result[createFieldSkinId(field)] = {
                    error: false,
                    hidden: !field.hidden,
                    required: !!field.required
                };
                return result;
            }, initial);
            const fieldsCSSStates = createFieldsCSSStates(this.props.orderedFields);
            return _.merge(state, fieldsCSSStates);
        },

        getCleanFormState() {
            return _.reduce(this.props.orderedFields, function (res, field) {
                const skinId = createFieldSkinId(field);
                res[skinId] = res[skinId] || {};
                res[skinId].value = '';
                return res;
            }, {});
        },

        getLanguage() {
            const lang = getLanguage(this.props.compProp.dataLang, this.props.userLanguage);
            return _.includes(SUPPORTED_LANGS, lang) ? lang : 'en';
        },

        getFormAriaLabel() {
            const lang = this.getLanguage();
            return coreUtils.translationsLoader.getTranslation('component_label', lang, this.getFormAriaLabelName(), this.getFormAriaLabelName());
        },

        getFormAriaLabelName() {
            return 'contact form';
        },

        /*override*/
        getActivityName() {
            if (this.props.isDynamicContactForm) {
                return 'DynamicContactFormActivity';
            }
            return 'ContactFormActivity';
        },

        /*override*/
        getFormFields() {
            const self = this;
            const fieldsData = _.reduce(self.props.orderedFields, function (result, field) {
                if (field.hidden) {
                    const label = field.fieldLabel;
                    const value = self.state[createFieldSkinId(field)].value || '';
                    result.displayed[label] = value; // we knowingly override fields with duplicate labels names (i.e. user input)
                    result.structured.push({
                        type: field.fieldType,
                        label,
                        value
                    });
                }
                return result;
            }, {displayed: {}, structured: []});
            fieldsData.getAll = function (fieldType) {
                return _.filter(fieldsData.structured, {type: fieldType});
            };
            return {
                newModel: true,
                data: fieldsData
            };
        },

        /*override*/
        getFieldsForActivityReporting() {
            return getFormBuilderActivityFields.call(this);
        },

        /*override*/
        getFieldLabels() {
            return _(this.props.orderedFields)
                .filter(function (field) {
                    return _.has(field, 'fieldType');
                })
                .map(function (field) {
                    return {type: field.fieldType, label: field.fieldLabel};
                })
                .value();
        },

        getFieldValue(skinId) {
            if (this.refsToInputComps[skinId]) {
                return this.refsToInputComps[skinId].value;
            } else if (this.refs[skinId]) {
                return this.refs[skinId].value;
            }

            return '';
        },

        /*override*/
        isFormValid() {
            const state = this.state;
            let newState = _.clone(state);
            const emailFieldState = getEmailFieldState.call(this);
            const emailValue = emailFieldState.value;
            const emailFieldSkinId = getEmailFieldSkinId.call(this);
            const isEmailValid = validateField.call(this, emailFieldState, emailFieldSkinId, emailValue);
            newState = getFormNewState.call(this, state, emailFieldSkinId, emailValue, isEmailValid);
            let allFieldsValid;
            if (isEmailValid) {
                allFieldsValid = _.reduce(this.props.orderedFields, function (allFieldsAreValid, field) {
                    const skinId = createFieldSkinId(field);
                    const fieldValue = getFieldValue.call(this, skinId);
                    const isValidField = validateField.call(this, state[skinId], skinId.toLowerCase(), fieldValue);
                    newState = _.assign(newState, getFormNewState.call(this, state, skinId, fieldValue, isValidField));
                    return allFieldsAreValid && isValidField;
                }.bind(this), true);
            }
            const firstInvalidField = _.findKey(newState, 'error');
            if (firstInvalidField && this.refsToInputComps[firstInvalidField]) {
                this.refsToInputComps[firstInvalidField].focus();
            } else if (this.refs[firstInvalidField]) {
                this.refs[firstInvalidField].focus();
            }
            this.setState(newState);
            return allFieldsValid;
        },

        /*override*/
        getInputName() {
            const nameFieldState = getNameFieldState.call(this);
            const name = nameFieldState ? nameFieldState.value : null;
            if (this.props.isExperimentOpen('sendContactFormEmailsViaPong')) {
                return name || null;
            }
            return name || 'n/a';
        },

        /*override*/
        getLangKeys(translations) {
            return translations.contactForm;
        },

        /*override*/
        getFormSkinProperties() {
            const fieldsData = createFieldsData.call(this);
            const messageFieldData = hasMessageField(fieldsData) ? removeMessageFieldData(fieldsData) : {};
            const messageFieldSkinProps = createMessageFieldSkinProps.call(this, messageFieldData);
            const fieldsHooks = createFieldsSkinProps.call(this, fieldsData);
            const notifications = createFormNotifications.call(this);
            return _.assign(messageFieldSkinProps, fieldsHooks, notifications, {
                privateMembers: fieldsData, // for tests only,
                'form-wrapper': {
                    'aria-label': this.getFormAriaLabel('contact form'),
                    'noValidate': true
                }
            });
        }
    };

    componentsCore.compRegistrar
        .register('wysiwyg.viewer.components.ContactForm', contactForm)
        .register('wysiwyg.viewer.components.DynamicContactForm', contactForm);

    skinsPackage.skinsMap.addBatch(skinsJson);

    return contactForm;
});
